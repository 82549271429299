import './App.css';
import Header from './components/Header';
import Home from './pages/Home';
import ApplicationRoutes from './routes/ApplicationRoutes';
import { Calendar } from "@progress/kendo-react-dateinputs";


function App() {
  return (
    <>
      <ApplicationRoutes />

    </>
  );
}

export default App;
