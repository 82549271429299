import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div class="py-16 text-center   bg-black mx-auto">
      <div className="grid grid-cols-1   content-center  mx-auto  text-white text-center gap-10">
        <div className=" text-center  inline-flex items-center">
          <img
            className="w-[200px]    mx-auto  pb-10 "
            src="mikailekmen-black.png"
          />
        </div>
      </div>

      <div class=" w-[70vw] ml-[15vw]  py-2">
        <div class="lg:flex lg:gap-8">
          <div class="mt-2 grid grid-cols-2 gap-2 lg:mt-0 lg:grid-cols-5 lg:gap-y-16">
            <div class="col-span-2 lg:col-span-3 lg:flex lg:items-end">
              <form class="w-full text-white"></form>
            </div>
          </div>
        </div>

        <div class="mt-4 border-t border-white/10 pt-2">
          <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <p class="text-center text-xs text-white lg:text-left">
              Copyright © 2022 All rights reserved.
            </p>

            <nav class="flex justify-center gap-x-4 text-xs text-white lg:justify-end">
              <a> Terms & Conditions </a>
              <a> Privacy Policy </a>
              <a> Cookies </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
